import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function EduToolKit () {
  return (
    <Layout pageInfo={{ pageName: "Publicaciones" }}>
    <SEO title="Publicaciones" />
    <br />
    <br />
    <h1>Publicaciones</h1>
    <br></br>
    
  </Layout>
  )
}